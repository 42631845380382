.login-expiration-container {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 100;
  width: 100%;
  display: flex;
  pointer-events: none;
  margin: 0;
  padding: 0;
}

@keyframes login-exp-alert-from-top {
  0% {
    margin-top: -30rem;
  }
  100% {
    margin-top: 1.5rem;
  }
}

@keyframes login-exp-alert-to-top {
  0% {
    margin-top: 1.5rem;
  }
  100% {
    margin-top: -30rem;
  }
}

.login-expiration-alert {
  width: 40rem;
  margin-right: auto;
  margin-left: auto;
  z-index: 1000;
  pointer-events: all;
  animation: login-exp-alert-from-top 400ms ease-in-out forwards;
}

.login-expiration-alert.ignored-exp-alert {
  animation: login-exp-alert-to-top 400ms ease-in-out forwards;
}

.force-logout-timeout {
  font-size: large;
}
.login-expiration-white-overlay {
  position: fixed;
  width: 100vw;
  height: 100vh;
  pointer-events: all;
  background-color: white;
  opacity: 0.5;
  z-index: 100;
  margin: 0;
  padding: 0;
  pointer-events: all;
}

.login-expiration-alert-smaller-container {
  position: absolute;
  display: flex;
  width: 100%;
}
.login-expiration-alert-smaller {
  z-index: 1000;
  margin-top: 0.4rem;
  margin-left: auto;
  margin-right: auto;
}

.landing-text {
  background-color: var(--color-prime);
  color: var(--color-2);
  padding: 0 5%;
  transition: margin 300ms ease-in-out;

  height: 100vh;
  overflow-y: auto;
  overflow-x: hidden;

  padding-bottom: 5rem;

  grid-column: 1;
  grid-row: 1;

  width: 30vw;
}

.landing-text.landing-text-closed {
  overflow: hidden;
}

.landing-page .landing-text {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
}

.landing-text .text-content,
.landing-text .legal-info {
  transition: opacity 300ms ease-in-out;
}

.landing-text.landing-text-closed .text-content,
.landing-text.landing-text-closed .legal-info {
  opacity: 0;
}

.landing-text.landing-text-opened .text-content,
.landing-text.landing-text-opened .legal-info {
  opacity: 1;
  transition-delay: 200ms;
}

.landing-page {
  margin: 0;
  padding: 0;
  display: grid;
  grid-template-columns: auto 2fr;
}

.landing-page .map > svg {
  width: 100%;
  max-height: 95vh;
}

.landing-page .title {
  margin-top: 15vh;
  margin-bottom: 5vh;
  text-align: center;
}

.landing-page .description {
  margin-bottom: 5vh;
}

.landing-map {
  padding: 0;
  grid-column: 2;
  grid-row: 1;
}

.toggle-landing-page-btn {
  background-color: white;
  position: absolute;
  border: 1px solid var(--color-prime);
  border-left-width: 0;
  border-top-right-radius: 0.5rem;
  border-bottom-right-radius: 0.5rem;
  padding: 0.5rem;
  transition: 400ms ease-in-out;
  cursor: pointer;
  top: 50vh;
  transform: translate(0, -50%);
  opacity: 0.5;
}

.toggle-landing-page-btn:hover {
  padding-left: 1rem;
  opacity: 1;
}

.toggle-landing-page-btn:hover .toggle-landing-page-icon {
  scale: 1.2;
}

.toggle-landing-page-icon {
  height: 1rem;
  color: var(--color-prime);
  transition: 300ms ease-in-out;
}

.toggle-landing-page-icon.landing-text-closed {
  transform: rotate(180deg);
}

.toggle-landing-page-icon:nth-child(2) {
  margin-left: -0.3rem;
}

.landing-page .legal-info {
  color: var(--color-4);
}

.landing-page-buttons {
  display: flex;
  z-index: 10;
  grid-column: 2;
  grid-row: 1;
  height: fit-content;
}

.main-page-map-container {
  display: flex;
  height: 100vh;
}

.landing-lan-switch-container {
  margin-left: -0.4rem;
}

.landing-page .landing-logo {
  width: 100%;
  margin: auto;
}

.single-logo-container {
  margin: auto;
}

.logos-row {
  margin-bottom: 2rem;
}

.footer-landing-page {
  /* footer used only on mobile devices */
  display: none;
}

.legal-text-landing-page {
  width: 100%;
  text-align: center;
}

.legal-text-landing-page p {
  margin: 0;
}

.landing-page-link-color {
  color: #6eb0fb;
}

.landing-page-link-color:hover {
  color: #4fa1fe;
}

.genetic-data-container {
  display: flex;
  position: relative;
}

.genetic-data-spinner {
  display: flex;
  justify-content: center;
}

.genetic-data-spinner-overlay {
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: var(--color-1);
  opacity: 0.8;
  display: flex;
  justify-content: center;
  align-items: center;
}

.gen-data-tab-content {
  display: flex;
}

.gen-data-view-edit-icon {
  color: var(--bs-success);
  font-size: small;
  margin-left: 0.5rem;
  margin-top: 0.25rem;
}

.gen-data-view-view-icon {
  color: var(--color-5);
  font-size: small;
  margin-left: 0.5rem;
  margin-top: 0.3rem;
}

/* TABLE */

.markers-table {
  --column-count: 5;

  display: grid;
  grid-template-columns: repeat(var(--column-count), 1fr);

  position: relative;
  margin-bottom: auto;
}

.markers-table .marker-cell {
  min-width: 6rem;
  margin-bottom: 1.3rem;
}

.markers-table .marker-name,
.markers-table .marker-values-container {
  padding-left: 0.5rem;
  border-left: 0.1rem solid var(--color-9);
  cursor: default;
}

.markers-table .marker-name {
  padding-top: 0.2rem;
  padding-bottom: 0.1rem;
  background-color: var(--color-5);
  color: var(--color-9);
}

.markers-table .marker-values-container {
  background-color: var(--color-2);
  display: flex;
  padding-top: 0.1rem;
  padding-bottom: 0.15rem;
  margin-top: 0.2rem;
}

.markers-table .value-dash-container {
  display: flex;
}

.markers-table .dash {
  margin: 0 0.2rem;
  color: var(--bs-danger);
}

.markers-table .marker-value {
  position: relative;
}

.markers-table .marker-value .origin {
  position: absolute;
  opacity: 0;
  transition: 200ms ease-in-out;
  top: 0;
  left: 1rem;
  z-index: 10;
  pointer-events: none;
  background-color: var(--color-1);
  border: 0.1rem solid var(--color-5);
  border-radius: 1rem;
  padding: 0.3rem 0.5rem;
  border-radius: 0.2rem;
}

.markers-table .marker-value:hover .origin {
  opacity: 1;
  transition: 200ms ease-in-out;
}

/* Origin file list */

.marker-files-title {
  padding-left: 0.7rem;
  border-left: solid 0.2rem var(--color-prime);
}

.origin-container {
  display: flex;
}

.origin-name {
  margin-top: auto;
  margin-bottom: auto;
}

.download-origin-file-btn {
  margin-top: auto;
  margin-bottom: auto;
  border: 0;
  background-color: transparent;
}

.download-origin-file-icon {
  color: var(--bs-success);
}

.genetic-data-view-warning-container {
  position: relative;
  margin-top: auto;
  margin-bottom: auto;
  color: var(--bs-danger);
}

.genetic-data-view-warning-container:hover .genetic-data-view-warning-message {
  opacity: 1;
}

.genetic-data-view-warning-message {
  opacity: 0;
  pointer-events: none;
  position: absolute;
  background-color: var(--bs-danger);
  color: white;
  top: 0;
  left: 1.3rem;
  z-index: 10;
  padding: 0.08rem 0.3rem;
  border-radius: 0.3rem;
  transition: 150ms ease-in-out;
  white-space: nowrap;
}

.gen-data-failed-alert {
  max-width: 25rem;
}

div.manage-staff-email-input {
  width: 20rem;
  background-color: var(--color-9);
}

.staff-input-with-alert-container {
  display: flex;
}

.staff-input-container {
  margin-right: auto;
  display: grid;
  grid-template-columns: auto auto auto;
  margin-bottom: 1rem;
  margin-top: 2.3rem;
}

.staff-success-alert {
  grid-column: span 3;
  margin-top: 1.5rem;
}

div.manage-staff-role-select {
  width: 13rem;
  background-color: var(--color-9);
}

.staff-management-spinner-container {
  display: flex;
  width: 100%;
}

.staff-management-spinner {
  margin-left: auto;
  margin-right: auto;
}

.staff-management-table-row > th,
.staff-management-table-row > td {
  padding-top: 0.6rem;
  padding-bottom: 0.6rem;
}

.manage-staff-table thead > tr th {
  font-weight: bold;
}

@media only screen and (max-width: 1050px) {
  .map-modal {
    --vertical-margins: 0.5rem;
    --horizontal-margins: 0.5rem;

    --modal-height: calc(100vh - 2 * var(--vertical-margins));
    --modal-width: calc(100vw - 2 * var(--vertical-margins));

    top: var(--vertical-margins) !important;
    left: var(--vertical-margins) !important;

    background-color: var(--color-9);
    overflow: auto;

    z-index: 1000 !important;

    width: var(--modal-width);
    height: var(--modal-height);

    display: grid;

    opacity: 1;
  }

  .map-modal:hover {
    opacity: 1;
  }

  .haplo-table-container {
    overflow: auto;
  }

  .haplo-table {
    margin-right: 1rem;
  }
}

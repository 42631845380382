.top-bar-container {
  height: var(--top-bar-height);
  background-color: var(--color-prime);
  display: flex;
}

.logo-picture {
  font-size: 2.1rem;
  margin-top: auto;
  margin-bottom: auto;
  margin-left: 0.1rem;
  margin-right: -0.3rem;
}

.logo-link {
  display: flex;
  text-decoration: none;
}

.glow {
  animation: glow 1s ease-in-out infinite alternate;
}

@keyframes glow {
  from {
    text-shadow: 0 0 0.5rem #fff;
  }
  to {
    text-shadow: 0 0 0.8rem #fff;
  }
}

.top-bar-middle {
  flex-grow: 1;
}

.logo-container {
  margin-left: 10px;
  height: var(--top-bar-height);
}

.logo-text {
  display: grid;
  height: var(--top-bar-height);
  line-height: var(--top-bar-height);
  grid-template-columns: repeat(10, auto);
  font-size: 30px;
  margin-left: 10px;
  float: right;
  color: var(--color-1);
  cursor: pointer;
}

.logo-text .first-letter {
  font-size: 30px;
  margin-left: 1px;
  transition: ease-in-out 200ms;
}

.logo-text:hover .first-letter {
  margin-left: 5px;
  transition: ease-in-out 200ms;
}

.logo-text .rest-of-word {
  overflow: hidden;
  font-size: 0;
  margin-top: 3px;
  transition: ease-in-out 200ms;
}

.logo-text:hover .rest-of-word {
  font-size: 20px;
  transition: ease-in-out 200ms;
}

.user-picture {
  height: 38px;
  border-radius: 100%;
  border: 1.5px solid var(--color-1);
  margin-top: 10px;
}

.user-name {
  height: var(--top-bar-height);
  line-height: var(--top-bar-height);
  font-size: 18px;
  color: var(--color-1);
  margin-left: 1em;
  margin-right: 1em;
  cursor: default;
}

.svg-menu-icon {
  fill: var(--color-1);
  transition: ease-in-out 200ms;
  cursor: pointer;
}

.user-menu-container {
  height: 100%;
  display: flex;
  border: none;
  background-color: transparent;
}

.user-menu-icon:hover {
  scale: 1.12;
}
.user-menu-icon {
  height: 50%;
  margin-top: auto;
  margin-bottom: auto;
  margin-right: 1rem;
  color: var(--color-2);
  cursor: pointer;
  transition: 200ms ease-in-out;
}

.option-list-container {
  position: absolute;
  margin-left: -15.5rem;
  margin-top: 3.5rem;
  z-index: 1000;
}

.option-list {
  overflow: hidden;
  position: absolute;
  width: 187px;
  max-height: 0;
  visibility: hidden;
  transition: ease-in-out 300ms;
  margin-left: 6rem;
  margin-top: 0.6rem;
}

.option-list.option-list-visible {
  height: fit-content;
  max-height: 500px;
  transition: ease-in-out 300ms;
  visibility: visible;
}

.user-menu-option {
  display: table;
  height: 45px;
  cursor: pointer;
  border-left: 0 solid var(--color-prime);
  color: var(--color-6);
  transition: ease-in-out 300ms;
  text-decoration: none;
  position: relative;
  width: 100%;
}

.user-menu-option:hover {
  border-left: 12px solid var(--color-2);
  transition: ease-in-out 150ms;
}

.user-menu-option a {
  text-decoration: none;
  color: inherit;
  padding-left: 15px;
  display: table-cell;
  vertical-align: middle;
  text-align: left;
}

.logout-btn {
  color: var(--color-prime);
  text-decoration: none;
}

.lang-switch-container {
  margin-top: auto;
  margin-bottom: auto;
}

.conflicting-data-spinner {
  position: absolute;
  top: 50%;
  left: 50%;
}

div.markers-successfully-saved-alert {
  width: fit-content;
  padding-right: 1rem;
}

div.no-selected-user-alert {
  width: fit-content;
  padding-right: 1.2rem;
}

div.no-conflict-alert {
  width: fit-content;
  padding-right: 1rem;
}

div.user-with-conflict-select {
  background-color: white;
  min-width: 20rem;
}

div.genetic-data-conflicts-table-container {
  width: fit-content;
}

.resulting-marker-value-input {
  width: 5rem;
  background-color: white;
  /* height: 5px; */
}

.conflict-table-row-unfilled {
  background-color: #ffeded;
}

.conflict-table-row-no-conflict {
  background-color: #efffed;
}

.custom-value {
  background-color: #feffed;
}

.conflict-table-controls-row {
  display: flex;
}

.display-aligned-marker-values-checkbox {
  margin-top: auto;
  margin-bottom: auto;
}

.tabs-content {
  --border-width: 0.12rem;
  background-color: var(--color-9);
  border-bottom: solid var(--border-width) var(--color-2);
}

.all-tabs-container {
  display: flex;
  overflow-y: auto;

  --top-highlight-width: 0.3rem;
  --border-width: 0.12rem;
  --padding-vertical: 0.6rem;
  --padding-horizontal: 1.5rem;
}

.tab {
  white-space: nowrap;
  border: 0;
  border-bottom: solid var(--border-width) var(--color-2);

  display: block;

  background-color: transparent;

  transition: background-color 200ms ease-in-out;
}

.tab:hover:not(.focused-tab) {
  background-color: var(--color-2);
  transition: background-color 200ms ease-in-out;
}

.tab.focused-tab {
  border: solid var(--border-width) var(--color-2);
  border-top: solid var(--top-highlight-width) var(--color-prime);
  border-bottom: 0;

  padding-top: calc(var(--padding-vertical) - var(--top-highlight-width));
  padding-bottom: var(--padding-vertical);
  padding-left: calc(var(--padding-horizontal) - var(--border-width));
  padding-right: calc(var(--padding-horizontal) - var(--border-width));

  background-color: var(--color-9);
}

.tab.not-focused-tab {
  padding: var(--padding-vertical) var(--padding-horizontal);
  padding-bottom: calc(var(--padding-vertical) - var(--border-width));
}

.all-tabs-container::after {
  content: '';
  flex: 1 1;
  margin-top: auto;
  border-bottom: solid var(--border-width) var(--color-2);
}

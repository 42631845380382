.haplo-group-info-container {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
}

.haplo-group-info-container > div {
  margin-bottom: 1rem;
}

.haplo-group-info-table-full-row {
  display: flex;
  justify-content: center;
}

.haplo-info-alert {
  width: 100%;
}

.haplo-info-user-table-container {
  max-height: 50vh;
  overflow-y: auto;
  padding: 0.3rem;
}

.haplo-detail-table-row {
  position: relative;
}

.haplo-detail-table-empty-column-explanation-container {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  transition: 200ms ease-in-out;
  opacity: 0;
  cursor: default;
}

.haplo-detail-table-empty-column-explanation-container:hover {
  opacity: 1;
}

.haplo-detail-eye-slash {
  color: var(--bs-danger);
}

div.haplo-detail-table-empty-column-explanation-alert {
  border-radius: 0;
}

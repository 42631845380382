@media only screen and (max-width: 1050px) {
  .user-name {
    display: none;
  }

  .logo-picture {
    display: none;
  }

  .logo-text div.first-letter {
    font-size: 1.8rem;
  }

  .logo-text div.rest-of-word {
    display: none;
  }

  .option-list-container {
    margin-top: 2.5rem;
  }
}

/* definitions of colors used by the app */
/* NOTE: color of the population density gradient in the map is hardcoded in features/Map/Map.tsx */
:root {
  --color-prime: rgb(61, 34, 161);
  --color-prime-light: rgb(255, 239, 240);
  --color-fail: rgb(255, 193, 193);
  --color-1: rgb(242, 242, 242);
  --color-2: rgb(225, 225, 225);
  --color-3: rgb(204, 204, 204);
  --color-4: rgb(165, 165, 165);
  --color-5: rgb(57, 87, 153);
  --color-6: rgb(46, 56, 116);
  --color-dark: rgb(44, 44, 44);
  --color-7: rgba(74, 156, 250, 0.8);
  --color-8: rgba(212, 78, 78, 0.8);
  --color-9: white;
  --color-10: pink;
  --color-link-on-dark: #9ee7ff;
  --color-link-on-dark-hover: #e0f7ff;
}

button.btn.btn-success {
  --bs-btn-bg: var(--color-prime);
  --bs-btn-border-color: var(--color-prime);
  --bs-btn-hover-bg: var(--color-prime);
  --bs-btn-hover-border-color: var(--color-6);
  --bs-btn-active-bg: var(--color-6);
  --bs-btn-active-border-color: var(--color-6);
}

.select__menu .select__menu-list .select__option {
  background-color: white;
  transition: ease-in-out 200ms;
  border-left: 0 solid var(--color-prime);
  color: var(--color-dark);
}

.select__menu .select__menu-list .select__option--is-focused {
  border-left: 10px solid var(--color-2);
  transition: ease-in-out 200ms;
}

.select__menu .select__menu-list .select__option--is-selected {
  border-left: 7px solid var(--color-prime);
  transition: ease-in-out 200ms;
}

div.select__control {
  border: 1px solid var(--color-3) !important;
  box-shadow: none !important;
}

div.select__control--is-focused {
  box-shadow: 0 0 1px 1px var(--color-prime) !important;
}

.select__value-container .select__multi-value {
  background-color: var(--color-1);
}

.select__value-container .select__multi-value .select__multi-value__remove:hover {
  background-color: var(--color-1);
  transition: ease-in-out 300ms;
}

.select__value-container .select__multi-value .select__multi-value__remove:hover {
  background-color: var(--color-2);
  transition: ease-in-out 300ms;
}

.select__value-container .select__multi-value .select__multi-value__remove:hover svg {
  transition: ease-in-out 300ms;
  scale: 1.8;
}

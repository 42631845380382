.error-alert-container {
  position: fixed;
  top: 0;
  left: 50%;
  transform: translate(-50%, 0);
  z-index: 100;

  margin-top: 1rem;
  width: 30rem;
}

.error-alert {
  margin-bottom: 0.6rem;
}

.map-modal {
  --modal-height: 350px;
  --modal-width: 700px;
  --table-width: 650px;
  --close-button-width: 40px;

  position: fixed;
  background-color: var(--color-1);
  overflow: hidden;
  width: var(--modal-width);
  height: var(--modal-height);

  display: grid;
  grid-template-columns: auto var(--close-button-width);
  grid-template-rows: max-content auto;

  opacity: 0.8;

  --animation-height: var(--modal-height); /* variable for animation */
  animation: animate-height-in 200ms ease-in-out;
}

.map-modal:hover {
  opacity: 0.95;
}

@keyframes border-pulsate {
  0% {
    border-color: var(--color-prime);
  }
  80% {
    border-color: var(--color-2);
  }
  100% {
    border-color: var(--color-prime);
  }
}

.map-modal .haplo-modal-title {
  cursor: grab;
}

.map-modal .haplo-modal-title.dragging-modal {
  cursor: grabbing;
}

.map-modal .haplo-modal-title div {
  margin-left: 30px;
  margin-top: 25px;
  margin-bottom: 25px;
  font-size: 30px;
  border-left: 4px solid var(--color-prime);
  padding-left: 10px;
  overflow: hidden;
  animation: border-pulsate 2s infinite;
  color: var(--color-6);
}

@keyframes region-animate-in {
  0% {
    margin-left: -300px;
  }
  20% {
    margin-left: -300px;
  }
  100% {
    margin-left: 0;
  }
}

.map-modal .haplo-modal-title div span {
  animation: region-animate-in 400ms ease-in-out;
}

.map-modal .haplo-modal-close {
  height: min-content;
  align-self: start;
  background: none;
  border: none;

  margin-top: 3px;
  font-size: 35px;
  cursor: pointer;
  transition: ease-in-out 200ms;
  color: var(--color-4);
}

@keyframes scale-pulsate {
  0% {
    scale: 1.1;
  }
  50% {
    scale: 0.8;
  }
  100% {
    scale: 1.1;
  }
}

.haplo-modal-close:hover {
  color: var(--color-prime);
  transition: ease-in-out 200ms;
  scale: 1.2;
  animation: scale-pulsate 1s infinite;
}

.haplo-table-container {
  grid-column: 1 / span 2;
  overflow-y: auto;
  overflow-x: hidden;
  margin-bottom: 30px;
  width: max-content;
}

.map-modal .haplo-table {
  margin-left: 30px;
  width: var(--table-width);
  border-collapse: collapse;
}

.map-modal .haplo-table thead tr :first-child {
  border-left: 5px solid var(--color-prime);
}

.map-modal .haplo-table thead tr {
  position: sticky;
  top: 0;
  background-color: var(--color-5);
  color: var(--color-1);
}

.map-modal .haplo-table th {
  font-size: 16px;
  font-weight: normal;
  padding-right: 10px;
  padding-top: 3px;
  padding-bottom: 3px;
  padding-left: 10px;
}

.map-modal .haplo-table td {
  transition: ease-in-out 200ms;
  padding-left: 5px;
}
.map-modal .haplo-table tbody tr {
  color: var(--color-6);
}

.map-modal .haplo-table tbody tr {
  border-left: 0 solid var(--color-1);
  transition: ease-in-out 200ms;
}

.map-modal .haplo-table tbody tr:hover {
  background-color: var(--color-2);
}

.tool-tip-container {
  padding: 0.5rem 1rem;
  color: var(--color-dark);
  background-color: var(--color-prime-light);
  border-radius: 0.5rem;
  z-index: 100;
  position: fixed;
  pointer-events: none;
  opacity: 0.93;
  width: max-content;
}

@media only screen and (max-width: 1050px) {
  .layout-container {
    --top-bar-height: 3rem;
    --footer-height: 4rem;

    display: grid;
    grid-template-columns: 100vw;
    grid-template-rows: var(--top-bar-height) auto auto auto auto auto;
  }

  .top-bar-in-main-layout-container {
    grid-column: 1 / span 1;
  }

  .masking-notice-container {
    grid-column: 1 / span 1;
  }

  .side-menu-in-main-layout-container {
    grid-column: 1 / span 1;
  }

  .content-container {
    grid-column: 1 / span 1;
  }

  .layout-footer-container {
    grid-column: 1 / span 1;
  }

  .content-container {
    min-height: 90vh;
  }

  .layout-footer-container {
    background-color: var(--color-6);
    padding-top: 1rem;
    color: var(--color-9);
  }
}

@import url('https://fonts.googleapis.com/css?family=Patrick+Hand+SC|Roboto&display=swap');
body {
  font-family: 'Roboto';
}

:root {
  --top-bar-height: 60px;
  --side-menu-width: 300px;
  --footer-height: 80px;
}

/* windows */

.window {
  border: 1.5px solid var(--color-4);
  border-radius: 5px;
  background-color: white;
}

/* Animations */

@keyframes animate-height-in {
  0% {
    height: 0%;
  }
  100% {
    height: var(--animation-height);
  }
}
@keyframes animate-max-height-in {
  0% {
    overflow: hidden;
    max-height: 0px;
  }
  100% {
    overflow: hidden;
    max-height: var(--animation-height);
  }
}

@keyframes animate-max-height-in-with-delay {
  0% {
    overflow: hidden;
    max-height: 0px;
    border-width: 0px;
  }
  50% {
    overflow: hidden;
    max-height: 0px;
    border-width: 0px;
  }
  100% {
    overflow: hidden;
    max-height: var(--animation-height);
  }
}

.language-switch-background {
  --toggle-width: 3.5rem;
  --toggle-height: 2rem;
  --switch-btn-margin: -0.2rem;
  --switch-time: 200ms;

  width: var(--toggle-width);
  height: var(--toggle-height);

  background-color: var(--color-9);
  border-radius: calc(var(--toggle-height) / 2);
  cursor: pointer;

  z-index: 1000;
}

.language-switch-toggle {
  --switch-btn-diameter: calc(var(--toggle-height) - 2 * var(--switch-btn-margin));

  background-color: var(--color-10);
  width: var(--switch-btn-diameter);
  height: var(--switch-btn-diameter);
  border-radius: calc(var(--switch-btn-diameter) / 2);
  margin-top: var(--switch-btn-margin);
  position: absolute;

  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
}

.language-switch-background.lang-cze .language-switch-toggle {
  margin-left: var(--switch-btn-margin);
  border: 1px solid var(--color-7);
  transition: var(--switch-time) ease-in-out;
}

.language-switch-background.lang-eng .language-switch-toggle {
  margin-left: calc(var(--toggle-width) - var(--switch-btn-margin) - var(--switch-btn-diameter));
  border: 1px solid var(--color-8);
  transition: var(--switch-time) ease-in-out;
}

.cols-container {
  overflow: hidden;
  display: flex;
  border-radius: calc(var(--toggle-height) / 2);
}

.cze-back-col {
  background-color: var(--color-7);
  height: var(--toggle-height);
  width: 0;
  transition: var(--switch-time) ease-in-out;
}

.language-switch-background.lang-cze .cze-back-col {
  width: var(--toggle-width);
  transition: var(--switch-time) ease-in-out;
}

.eng-back-col {
  background-color: var(--color-8);
  height: var(--toggle-height);
  width: 0;
  transition: var(--switch-time) ease-in-out;
}

.language-switch-background.lang-eng .eng-back-col {
  width: var(--toggle-width);
  transition: var(--switch-time) ease-in-out;
}

@media only screen and (max-width: 1050px) {
  .side-menu-container {
    --closed-menu-height: 40px;
  }

  button.side-menu-toggle-btn {
    height: var(--closed-menu-height) !important;
    min-height: var(--closed-menu-height) !important;

    background-color: var(--color-7);
    border: none;
    display: block;
  }

  .side-menu-item {
    font-size: medium;
    max-height: 3rem;
    border-bottom: 0.08rem solid var(--color-2);
  }

  .side-menu-container {
    min-height: 0;
  }

  .side-menu-container.site-menu-closed {
    height: var(--closed-menu-height);
    overflow: hidden;
  }
}

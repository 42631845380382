.phylo-info-container {
  position: absolute;
  pointer-events: none;
  display: grid;
  grid-template-columns: max-content auto;
  grid-template-rows: max-content;
}

svg.phylogenetic-tree-canvas {
  width: 100%;
  height: 100%;
}
svg.phylogenetic-tree-canvas g.nodes g {
  cursor: pointer;
}

.edit-family-node-title {
  padding-left: 0.5rem;
  border-left: 0.2rem solid var(--color-prime);
}

.family-tree-edit-form-error-msg {
  width: 100%;
  background-color: var(--bs-danger);
  color: var(--color-1);
  padding: 0.5rem 1.5rem;
}

.MuiTablePagination-selectLabel.css-pdct74-MuiTablePagination-selectLabel,
.MuiTablePagination-displayedRows.css-levciy-MuiTablePagination-displayedRows {
  margin-top: auto;
  margin-bottom: auto;
}

.logs-spinner-container {
  display: flex;
  justify-content: center;
}

.log-row-failed-request {
  background-color: var(--color-fail);
}

.logs-table-row {
  opacity: 1;
  transition: 200ms ease-in-out;
}

.logs-table-row.loading-new-page-for-logs-table {
  opacity: 0.2;
  transition: 200ms ease-in-out;
}

.logs-icon-color {
  color: var(--color-7);
}

.show-log-detail-icon {
  cursor: pointer;
  transition: 200ms ease-in-out;
}

.show-log-detail-icon:hover {
  color: var(--color-prime);
  transition: 200ms ease-in-out;
}

.log-table-title-row th {
  font-weight: bold;
}

.logs-person-icon-info {
  color: var(--color-7);
}

.genetic-comparison .modal-dialog {
  max-width: min(1200px, 95vw);
}

.comparison-container {
  /* If you change this dont forget to change constant in code accordingly */
  --markers-per-row: 8;
  --are-different-strong-color: #ff3535;
  --are-different-color: #ffcfcf;
  --are-same-strong-color: #11cf00;
  --are-same-color: #acffa5;
  --bottom-border-width: 0.1rem;

  display: grid;
  grid-template-columns: 2fr repeat(var(--markers-per-row), 1fr);
}

.comparison-cells-container {
  margin-bottom: 2rem;
  height: 100%;
}

.comparison-cells-container.no-compare-cells > :last-child {
  border-bottom: var(--bottom-border-width) var(--color-5) solid;
}

.comparison-cells-container:not(.no-compare-cells) > :last-child.values-are-same {
  border-bottom: var(--bottom-border-width) var(--are-same-strong-color) solid;
}

.comparison-cells-container:not(.no-compare-cells) > :last-child {
  border-bottom: var(--bottom-border-width) var(--are-different-strong-color) solid;
}

.comparison-cells-container > div {
  padding: 0.2rem 0.4rem;
  margin: 0.1rem 0.1rem;
}

.no-marker-value {
  opacity: 0;
}

.comparison-cell-title {
  background-color: var(--color-5);
  color: var(--color-9);
  padding: 0.3rem 0.4rem;
  display: flex;
}

.comparison-cell-title.values-are-same {
  background-color: var(--are-same-strong-color);
}

.comparison-cell-title.values-are-different {
  background-color: var(--are-different-strong-color);
}

.comparison-cell-row {
}

.comparison-cell-row.values-are-same {
  background-color: var(--are-same-color);
}

.comparison-cell-row.values-are-different {
  background-color: var(--are-different-color);
}

.comparison-cell-title-are-same-icon,
.comparison-cell-title-are-different-icon {
  margin-top: auto;
  margin-bottom: auto;
  margin-left: auto;
}
.comparison-cell-title-are-different-icon {
  color: var(--are-different-color);
}
.comparison-cell-title-are-same-icon {
  color: var(--are-same-color);
}

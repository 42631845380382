@media only screen and (max-width: 1050px) {
  .toggle-landing-page-btn {
    display: none;
  }

  .landing-page-buttons {
    grid-row: 1;
    grid-column-start: 1;
    grid-column-end: 2;
    width: 95%;
  }

  .landing-text {
    grid-row: 2;
    grid-column-start: 1;
    grid-column-end: 2;
    width: 100%;
    height: fit-content;
  }

  .landing-map {
    grid-row: 3;
    grid-column-start: 1;
    grid-column-end: 2;
    height: 100vh;
  }

  .landing-lan-switch-container {
    margin-left: auto;
  }

  .footer-inside-landing-text {
    display: none;
  }

  .footer-landing-page {
    padding: 2rem 5rem;

    background-color: var(--color-prime);

    display: block;

    grid-row: 4;
    grid-column-start: 1;
    grid-column-end: 2;
  }
}

.my-haplo-group-help-container {
  display: flex;
  background-color: var(--color-9);
  padding: 0.4rem 0.5rem;
  border-top: 0.15rem solid var(--color-prime);
  border-bottom: 0.15rem solid var(--color-prime);
  border-radius: 0.6rem;
  width: fit-content;
  max-width: 25rem;
  height: fit-content;
}

.mock-haplo-node {
  --r: 6rem;
  width: var(--r);
  min-width: var(--r);
  height: var(--r);
  border: 2px solid red;
  border-radius: var(--r);
  justify-content: center;
  align-items: center;
  display: flex;
  margin-top: auto;
  margin-bottom: auto;
}

@keyframes pulsing {
  0% {
    width: var(--max-r);
    height: var(--max-r);
  }
  50% {
    width: var(--min-r);
    height: var(--min-r);
  }
  100% {
    width: var(--max-r);
    height: var(--max-r);
  }
}

.mock-haplo-node:not(.disabled-node)::before {
  --max-r: 8rem;
  --min-r: 6.5rem;
  position: absolute;
  width: 7rem;
  height: 7rem;
  border-radius: var(--max-r);
  border: 0.2rem solid;
  content: '';
  animation: pulsing ease-in-out 1000ms infinite;
  border-color: inherit;
}

.mock-haplo-node.disabled-node {
  opacity: 0.3;
}

.mock-haplo-node span {
  padding: 0.4rem;
  font-size: 1.2rem;
  white-space: nowrap;
}

.my-haplo-group-help-text {
  margin: auto;
  margin-left: 2rem;
  margin-right: 1rem;
}

.no-haplo-group-container {
  padding: 1.3rem 1rem;
}

.help-text-snd-row {
  font-weight: bold;
}

.help-text-thr-row {
  font-size: small;
}

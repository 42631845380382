.message-popup-container {
  position: absolute;
  margin-left: 35%;
  margin-right: 35%;
  width: 30%;
  margin-top: 2em;
  z-index: 10000;
  display: flex;
  flex-direction: column;
}

.message-popup {
  width: 100%;
  margin-bottom: 1em;
  position: relative;
}

.message-popup > * {
  z-index: 100;
}

.MuiAlert-icon {
  z-index: 1000 !important;
}

.message-time-to-live-progress {
  position: absolute;
  background-color: #ff9e9e;
  top: 0;
  left: 0;
  border-radius: 4px;
  z-index: -1;
  height: 0.25rem;
  width: 100%;
  transform-origin: left;
  animation: shrink linear forwards;
}

@keyframes shrink {
  from {
    transform: scaleX(1);
  }
  to {
    transform: scaleX(0);
  }
}

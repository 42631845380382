.side-menu {
  background-color: var(--color-1);
  padding-right: 0;
  position: relative;
}

.side-menu-container {
  height: 100%;
  min-height: calc(100vh - var(--top-bar-height) - var(--footer-height) - var(--masking-notice-height));
  display: flex;
  flex-flow: column;
}

.side-menu-container::after {
  flex-grow: 1;
  content: '';
  width: auto;
}

.side-menu-container :first-child.side-menu-item {
  border-top: 0px solid black;
  padding-top: 0px;
}

.side-menu-item {
  padding-top: 1px;
  padding-bottom: 1px;

  flex: 0 1 70px;
  color: var(--color-6);
  text-decoration: none;
  display: table;
  transition: border-left ease-in-out 300ms;
  font-size: 18px;
}

.side-menu-toggle-btn {
  display: none;
}

.side-menu-item div {
  display: table-cell;
  vertical-align: middle;
  padding-left: 20px;
}

.side-menu-item.selected-side-menu-item,
.side-menu-item:hover {
  background-color: var(--color-prime);
  color: rgb(var(--bs-white-rgb));
  border-right: 0px;
}

.side-menu-item,
.side-menu-container::after {
  border-right: 1px solid var(--color-2);
}

@import url('https://fonts.googleapis.com/css?family=Patrick+Hand+SC|Roboto&display=swap');
body {
  font-family: 'Roboto';
}

:root {
  --top-bar-height: 60px;
  --side-menu-width: 300px;
  --footer-height: 80px;

  --bs-link-hover-color: var(--color-5);
}

.content {
  background-color: var(--color-1);
  overflow: scroll;
}

.user-form-container {
  max-width: 50%;
}

.content {
  padding: 0;
  overflow: hidden;
}

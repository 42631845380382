@keyframes context-enter {
  0% {
    /* has to there so that react component can read elements dimensions before animation  */
    opacity: 0;
  }
  1% {
    opacity: 1;
    max-height: 0px;
    overflow: hidden;
  }
  100% {
    max-height: 500px;
  }
}

.context-menu-container {
  position: fixed;
  display: flex;
  overflow: hidden;
  flex-direction: column;
  background-color: white;
  border-radius: 0.3rem;
  border: 0.1rem solid var(--color-4);
  min-width: 10rem;
  animation: forwards 900ms context-enter;
  z-index: 200;
}

.context-menu-option {
  padding: 0.5rem 1rem;
  padding-left: 0.8rem;
  cursor: pointer;
  border: 0;
  border-left: 0.4rem solid var(--color-3);
  transition: 200ms ease-in-out;
  white-space: nowrap;
  background-color: transparent;
  width: 100%;
  text-align: left;
}

.context-menu-option.disabled-option {
  opacity: 0.5;
  cursor: default;
}

.context-menu-option:not(.disabled-option):hover {
  border-left: 0.4rem solid var(--color-prime);
  transition: 200ms ease-in-out;
}

.disabled-option {
  cursor: default;
}

.context-menu-option.warning-color {
  color: var(--bs-danger);
}
.context-menu-option:not(.disabled-option).warning-color:hover {
  border-color: var(--bs-danger);
}

.context-menu-option .option-label-container {
  display: flex;
}

.context-menu-option .option-label-container .option-text-label,
.context-menu-option .option-label-container .option-arrow-icon {
  margin-top: auto;
  margin-bottom: auto;
}

.context-menu-option .option-label-container .option-arrow-icon {
  font-size: 0.7rem;
  margin-left: auto;
  padding-left: 1rem;
}

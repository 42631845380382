.map > svg {
  max-height: 100%;
  max-width: 100%;
}

.map {
  height: 100%;
  width: 100%;
  overflow: hidden;
  margin-top: auto;
  display: flex;
}

.map-canvas {
  margin-top: auto;
  margin-bottom: auto;
}

.haplo-map-params-container {
  --option-window-width: 400px;
  --option-window-horizontal-margins: 20px;

  width: var(--option-window-width);
  max-width: calc(100vw - 2 * var(--option-window-horizontal-margins));

  position: absolute;
  margin-top: 20px;
  right: var(--option-window-horizontal-margins);
  background-color: var(--color-prime-light);

  border-top: 1px solid var(--color-prime);
  border-bottom: 1px solid var(--color-prime);
  border-radius: 10px;

  --animation-height: 600px; /* max height */
  animation: animate-max-height-in-with-delay 800ms ease-in-out;
}

g.kraje > path {
  cursor: pointer;
}

.haplo-params-title {
  font-size: 24px;
}

.haplo-params-title span {
  border-left: 3px solid var(--color-prime);
  padding-left: 8px;
  color: var(--color-6);
}

.haplo-params-title {
  --bs-accordion-active-bg: var(--bs-accordion-bg);
  --bs-accordion-active-color: var(--bs-accordion-color);
  --bs-accordion-btn-focus-box-shadow: none;
}

.haplo-param-label {
  margin-bottom: 10px;
  font-size: 17px;
  color: var(--color-6);
}

.haplo-param {
  margin-bottom: 15px;
}

.tooltip-kraj {
  color: var(--color-5);
  font-size: medium;
  margin-top: 0.2rem;
}

.tooltip-chunk {
  font-size: large;
}

.map-spinner {
  width: 100%;
  height: 100%;
  display: flex;
}
.map-spinner > div {
  margin: auto;
}

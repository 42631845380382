.footer {
  background-color: var(--color-2);
  color: var(--color-5);
}

.footer-container {
  width: 100%;
  font-size: 0.9rem;
}

.footer-content {
  text-align: center;
}

.footer-content a {
  color: inherit;
}

.manage-rights-feature-container {
  display: grid;
  grid-template-columns: 1fr 1fr;
}

.manage-rights-feature-container > div {
  margin-bottom: 4rem;
}

.user-rights-remove-btn {
  margin-top: 0.15rem;
}

.manage-user-rights-table tbody th,
.manage-user-rights-table tbody td {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}

.manage-rights-add-user-container {
  display: flex;
}

.manage-user-email-input div {
  background-color: white;
}

.manage-user-email-input p {
  position: absolute;
  top: 2.5rem;
}

button.manage-rights-add-user-btn {
  white-space: nowrap;
  padding-left: 2rem;
  padding-right: 2rem;
}

.user-right-table-is-loading {
  opacity: 0.5;
  pointer-events: none;
}

button.rights-management-explore-user-btn {
  border-radius: 20rem;
}

.rights-section-container {
  margin-right: 5rem;
}

.masking-notice-container {
  background-color: var(--color-prime);
  color: var(--color-1);
  align-items: baseline;
  display: flex;
  justify-content: center;

  grid-column: 1 / span 2;
  height: var(--masking-notice-height);
}

.masking-notice button {
  color: inherit !important;
}

.layout-container {
  --side-menu-width: 25rem;
  --top-bar-height: 4rem;
  --footer-height: 9rem;
  --masking-notice-height: 0rem;

  display: grid;
  grid-template-columns: var(--side-menu-width) auto;
  grid-template-rows: var(--top-bar-height) auto var(--footer-height);
}

.layout-container.is-masking-view {
  grid-template-rows: var(--top-bar-height) auto auto var(--footer-height);
  --masking-notice-height: 2.5rem;
}

.login-expiration-container {
  position: absolute;
}

.top-bar-in-main-layout-container {
  grid-column: span 2;
}

.content-container {
  grid-row: span 2;
}

.layout-footer-container {
  grid-column: 1;
  font-size: small;
  overflow-y: auto;
  display: flex;
  border-right: 1px solid var(--color-2);
}

.layout-footer-container > div {
  margin-top: auto;
}

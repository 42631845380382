.header {
  padding-left: 0.8rem;
  padding-top: 0.3rem;
  padding-bottom: 0.3rem;
  border-left: 0.4rem solid var(--color-prime);
  color: var(--color-dark);
}

.header-3,
h3.header {
  padding-left: 0.6rem;
  padding-top: 0.2rem;
  padding-bottom: 0.2rem;
  border-left: 0.25rem solid var(--color-prime);
  color: var(--color-dark);
}

.header-5,
h5.header {
  padding-left: 0.4rem;
  padding-top: 0.2rem;
  padding-bottom: 0.2rem;
  border-left: 0.2rem solid var(--color-prime);
  color: var(--color-dark);
}

.relative-card {
  padding-left: 1rem;
  padding-right: 1rem;
  width: 100%;
  --border-radius: 0.3rem;
  margin-bottom: 1.3rem;
}

.relative-card:last-child {
  margin-bottom: 2rem;
}

.relative-card-header {
  display: flex;
  width: 100%;
  background-color: var(--color-5);
  color: var(--color-9);
  font-size: large;
  padding-top: 0.4rem;
  padding-bottom: 0.4rem;

  border-top-left-radius: var(--border-radius);
  border-top-right-radius: var(--border-radius);
}

.relative-info-btn,
.relative-name {
  margin-top: auto;
  margin-bottom: auto;
}

.relative-name {
  flex-grow: 1;
}

.relative-card-body {
  display: flex;
  border-bottom: 0.1rem solid var(--color-5);

  border-bottom-left-radius: var(--border-radius);
  border-bottom-right-radius: var(--border-radius);
}

.relative-card-body > * {
  flex-grow: 1;
}

.relative-comparison-btn {
  flex-grow: unset;
  margin-top: auto;
  margin-bottom: auto;
  height: fit-content;
}

.logs-filter-header {
  border-left: 0.3rem solid var(--color-prime);
  padding-left: 0.8rem;
  margin: 1rem 0 0.5rem 2rem;
}

.log-filter-window {
  width: 100%;
  background-color: var(--color-9);
  border-top: 0.13rem solid var(--color-prime);
  border-bottom: 0.13rem solid var(--color-prime);
  border-radius: 0.3rem;
  margin: 2.5rem 0 1.5rem 0;
}

.logs-filter-row {
  display: flex;
  flex-wrap: wrap;
  margin: 1rem 2rem;
}

.logs-filter-outline {
  display: flex;
  position: relative;
  border-top: 0.08rem solid var(--color-5);
  padding: 1.2rem 1rem 0.6rem 1rem;
  margin-top: 1rem;
}
.logs-filter-outline:not(:last-child) {
  margin-right: 1rem;
}

.log-filter-outline-title {
  position: absolute;
  top: -0.7rem;
  left: 0.5rem;
  background-color: var(--color-9);
  color: var(--color-5);
  padding: 0 0.2rem;
}

.log-filter-input:not(:last-child) {
  padding-right: 1rem;
}

div.logs-filter-select-input {
  min-width: 13rem;
}

.log-filter-input-email input {
  min-width: 18rem;
}

.log-filter-request-id-input input {
  min-width: 15rem;
}

.logs-filter-spinner-container {
  display: flex;
  justify-content: center;
}
